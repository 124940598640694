<template>
  <Main>
    <div v-if="transactions.data" class="transactions">
      <sdPageHeader :title="i18n.t('menuItems.transactions')"></sdPageHeader>
      <a-row type="flex" justify="center">
        <a-col :xs="24">
          <sdCards headless>
            <TransactionsTable :transactions="transactions" :isLoading="isLoading" />
          </sdCards>
        </a-col>
      </a-row>
    </div>
    <div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </Main>
</template>

<script>
import { Main } from '../styled';
import { defineComponent, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const TransactionsTable = defineAsyncComponent(() => import('./overview/TransactionsTable'));

const Transactions = defineComponent({
  name: 'Transactions',
  components: { Main, TransactionsTable },

  setup() {
    const { state } = useStore();
    const i18n = useI18n();

    const isLoading = computed(() => state.transactions.isLoading);
    const transactions = computed(() => state.transactions);

    return {
      i18n,
      isLoading,
      transactions,
    };
  },
});

export default Transactions;
</script>
