<template>
  <Main>
    <sdPageHeader :title="i18n.t('menuItems.contests')"> </sdPageHeader>
    <a-row :gutter="15" v-if="contests">
      <a-col :lg="24" :md="24" :xs="24" v-for="contest of contests" :key="contest.id">
        <ContestsCard
          :contest="contest"
          v-model:traitModalVisible="buyTraitModalVisible"
          v-model:changeContest="changeContest"
          @changeModal="modalChangeActivity"
        />
      </a-col>
    </a-row>
    <div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>
    <sdCards headless v-if="contests?.length == 0">
      <ErrorWrapper class="contest__error">
        <img :src="require(`@/static/img/pages/404.svg`)" alt="404" />
        <p>{{ i18n.t('contests.page.contestsNotFound') }}</p>
      </ErrorWrapper>
    </sdCards>
    <PerticipateContestModal
      :contest="changeContest"
      :visible="isModalActive"
      @closeModal="modalChangeActivity"
      v-model:buyTraitVisible="buyTraitModalVisible"
    />
    <BuyTrait :traits="changeContest?.attributes.attempts_count" v-model:buyTraitVisible="buyTraitModalVisible" />
  </Main>
</template>
<script>
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, reactive, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import PerticipateContestModal from './overview/PerticipateContestModal.vue';
import { ErrorWrapper } from '../pages/style';

const ContestsCard = defineAsyncComponent(() => import('./overview/ContestsCard'));
const BuyTrait = defineAsyncComponent(() => import('@/components/modals/BuyTrait'));

const ViewPage = {
  name: 'ViewPage',
  components: {
    Main,
    TableWrapper,
    ErrorWrapper,
    ContestsCard,
    PerticipateContestModal,
    BuyTrait,
  },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());
    const changeContest = ref();
    const isModalActive = ref(false);
    const buyTraitModalVisible = ref(false);

    const modalChangeActivity = (data) => {
      isModalActive.value = !isModalActive.value;
      if (data?.id) {
        changeContest.value = data;
      } else {
        changeContest.value = null;
      }
    };

    const contests = computed(() => state.contests.data);

    const changeBuyTraitModal = () => {
      buyTraitModalVisible.value = !buyTraitModalVisible.value;
      changeContest.value = null;
    };

    onMounted(() => {
      dispatch('axiosContestsGetData');
      if (!state.shop.offersData.length) {
        dispatch('axiosOffersGetData');
      }
    });
    return {
      i18n,
      contests,
      changeContest,
      isModalActive,
      buyTraitModalVisible,
      modalChangeActivity,
      changeBuyTraitModal,
    };
  },
};

export default ViewPage;
</script>
