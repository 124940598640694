<template>
  <Main>
    <div v-if="products" class="trust-products">
      <sdPageHeader :title="i18n.t('menuItems.trust-products')"></sdPageHeader>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 30px">
        <p v-html="i18n.t('trust.info', { email: 'hello@mthub.io' })"></p>
      </div>
      <a-row :gutter="15">
        <a-col class="w-100" :xl="12" :lg="24" :md="24" v-for="product of products" :key="product.name">
          <sdCards class="start__header investor-card">
            <div class="trust-product-content">
              <sdHeading class="product-single-title" as="h5">
                {{ product.title }}
              </sdHeading>
              <a-row :gutter="15">
                <a-col :lg="12" :md="12">
                  <a-row :gutter="15">
                    <a-col :lg="12" :md="12">{{ i18n.t('trust.days_title') }}</a-col>
                    <a-col :lg="12" :md="12" class="trust-product__value">
                      {{ product.days }} {{ i18n.t('trust.days') }}
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :lg="12" :md="12">{{ i18n.t('trust.min_title') }}</a-col>
                    <a-col :lg="12" :md="12" class="trust-product__value">{{ product.min }} USDT</a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :lg="12" :md="12">{{ i18n.t('trust.percent_title') }}</a-col>
                    <a-col :lg="12" :md="12" class="trust-product__value">
                      {{ product.percent ? i18n.t('trust.percent_y') : i18n.t('trust.percent_n') }}
                    </a-col>
                  </a-row>
                  <a-row :gutter="15">
                    <a-col :lg="12" :md="12">{{ i18n.t('trust.rate_title') }}</a-col>
                    <a-col :lg="12" :md="12" class="trust-product__value">{{ product.rate }} %</a-col>
                  </a-row>
                </a-col>
                <a-col :lg="12" :md="12">{{ product.description }}</a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :lg="24" :md="24"></a-col>
              </a-row>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </div>
    <div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </Main>
</template>

<script>
import { Main } from '../styled';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

// const TransactionsTable = defineAsyncComponent(() => import('./overview/TransactionsTable'));

const TrustProducts = defineComponent({
  name: 'TrustProducts',
  // components: { Main, TransactionsTable },
  components: { Main },

  setup() {
    const { state, dispatch } = useStore();
    const i18n = useI18n();

    const isLoading = computed(() => state.trust.isLoading);
    const products = computed(() => state.trust.products);

    dispatch('trustProductsGetData');

    return {
      i18n,
      isLoading,
      products,
    };
  },
});

export default TrustProducts;
</script>

<style scoped lang="scss">
.trust-product {
  &__value {
    font-weight: 600;
  }
}
</style>
